import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { CertificationAPI } from 'api';
import { FindOptions } from 'api/common';
import { RootState } from 'app/index';
import { LOADING_STATUS } from 'utils/constants';

export const findCertificationsForAdmin = createAsyncThunk<any, any>(
  'certification/index',
  async (options: FindOptions, thunkAPI) => {
    try {
      const response = await CertificationAPI.findByAdmin(options);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createCertification = createAsyncThunk(
  'certification/createCertification',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await CertificationAPI.create(body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const removeCertification = createAsyncThunk(
  'certification/removeCertification',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await CertificationAPI.remove(id);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

interface Update {
  id: string;
  body: any;
}
export const updateCertification = createAsyncThunk(
  'certification/updateCertification',
  async (params: Update, { rejectWithValue }) => {
    try {
      const { id, body } = params;
      const response = await CertificationAPI.update(id, body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  list: [],
  loading: LOADING_STATUS.idle,
  error: null,
  isVisibleCreateModal: false,
} as any;

const certificationSlice = createSlice({
  name: 'certification',
  initialState,
  reducers: {
    toggleCreateCertificationModal: state => {
      state.isVisibleCreateModal = !state.isVisibleCreateModal;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(findCertificationsForAdmin.pending, state => {
        state.loading = LOADING_STATUS.pending;
      })
      .addCase(findCertificationsForAdmin.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.list = data;
        state.loading = LOADING_STATUS.fulfilled;
      })
      .addCase(
        findCertificationsForAdmin.rejected,
        (state, { payload: error }) => {
          state.loading = LOADING_STATUS.rejected;
          state.error = error;
        }
      );
  },
});

export const { toggleCreateCertificationModal } = certificationSlice.actions;

export default certificationSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FindOptions } from 'api/common';
import { OrganizationApi } from 'api/organization';
import { RootState } from 'app/index';
import { LOADING_STATUS } from 'utils/constants';

export const findOrganizations = createAsyncThunk<any, any>(
  'organization/index',
  async (options: FindOptions, thunkAPI) => {
    try {
      const response = await OrganizationApi.filter(options);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const checkExistOrganization = createAsyncThunk<any, any>(
  'organization/exists',
  async (params, thunkAPI) => {
    try {
      const response = await OrganizationApi.checkExists(params?.name);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const createOrganization = createAsyncThunk(
  'organization/createOrganization',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await OrganizationApi.create(body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const removeOrganization = createAsyncThunk(
  'organization/removeOrganization',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await OrganizationApi.remove(id);
      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

interface Update {
  id: string;
  body: any;
}
export const updateOrganization = createAsyncThunk(
  'category/updateOrganization',
  async (params: Update, { rejectWithValue }) => {
    try {
      const { id, body } = params;
      const response = await OrganizationApi.update(id, body);

      return response;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  list: [],
  loading: LOADING_STATUS.idle,
  error: undefined,
  isVisibleCreateModal: false,
} as any;

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    toggleCreateOrganizationModal: state => {
      state.isVisibleCreateModal = !state.isVisibleCreateModal;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(findOrganizations.pending, state => {
        state.loading = LOADING_STATUS.pending;
      })
      .addCase(findOrganizations.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.list = data;
        state.loading = LOADING_STATUS.fulfilled;
      })
      .addCase(findOrganizations.rejected, (state, { payload: error }) => {
        state.loading = LOADING_STATUS.rejected;
        state.error = error;
      });
  },
});

export const { toggleCreateOrganizationModal } = organizationSlice.actions;

export default organizationSlice.reducer;

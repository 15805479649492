/* eslint-disable no-useless-catch */
import { serialize } from '../utils';
import { FindOptions } from './common';
import { entityService } from './services';
// #endregion Local Imports

const pathname = '/certifications';

export const CertificationAPI = {
  findByAdmin: async (option: FindOptions) => {
    let url = `${pathname}/admin`;
    const query = serialize(option);
    url += query;

    try {
      const response = await entityService.get(url);

      return response;
    } catch (error) {
      throw error;
    }
  },
  create: async (body: any) => {
    try {
      const response = await entityService.post(`${pathname}`, body);
      return response;
    } catch (error) {
      throw error;
    }
  },
  update: async (id: string, body: any = {}) => {
    const url = `${pathname}/${id}`;

    try {
      const response = await entityService.patch(url, body);
      return response;
    } catch (error) {
      throw error;
    }
  },
  remove: async (id: string) => {
    const url = `${pathname}/${id}`;

    try {
      const response = await entityService.delete(url);
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export interface CreateCertificationBody {
  name: string;
  logo: string;
}

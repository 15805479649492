import { unwrapResult } from '@reduxjs/toolkit';
import { Form } from 'antd';
import { ButtonType, Input, Modal, Notification, Text } from 'farmhub-ui-core';
import * as React from 'react';
import styled from 'styled-components';

import { useAppDispatch } from 'app';
import { CButton } from 'components';
import { UploadSingle } from 'components/Form';
import { TitleText } from 'components/Text';
import {
  checkExistCategory,
  createCategory,
  updateCategory,
} from 'slice/categorySlice';
import { uploadSingleFile } from 'slice/uploadSlice';
import {
  checkFirstCharIsNotSpace,
  checkOnlySpaces,
  handleUploadSingleImage,
} from 'utils/function';

interface CategoryModalProps {
  isVisible?: boolean;
  setIsVisible: (e: boolean) => void;
  category?: any;
  isEdit?: boolean;
  setReload?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  setCategory?: React.Dispatch<React.SetStateAction<any>>;
}

const handleChangeAvatar = e => e && e.file;

const CategoryModal = ({
  isVisible,
  setIsVisible,
  category,
  isEdit,
  setReload,
  setIsEdit,
  setCategory,
}: CategoryModalProps): JSX.Element => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const resetState = () => {
    setIsEdit?.(false);
    setIsVisible(false);
    setReload?.(prevState => !prevState);
    setCategory?.(undefined);
    form.resetFields();
  };
  const onCancel = () => {
    setIsVisible!(false);
    setIsEdit?.(false);
    setCategory?.(undefined);
    form.resetFields();
  };

  React.useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        avatar: category.avatar,
        categoryName: category.name,
      });
    }
  }, [category?.avatar, category?.name, form, isEdit]);

  const handleSubmitBodyCreate = () => {
    form.validateFields().then(async values => {
      try {
        setIsLoading(true);
        const hasExistsName = await dispatch(
          checkExistCategory({ name: form.getFieldValue('categoryName') })
        );
        const data = unwrapResult(hasExistsName);
        if (data.code === 200) {
          Notification('error', 'Category name is exists!');
        }
      } catch (exists: any) {
        if (exists?.statusCode === 404) {
          if (isEdit) {
            let avatar;
            if (form.getFieldValue('avatar') !== category?.avatar) {
              avatar = await handleUploadSingleImage(values.avatar, e =>
                dispatch(uploadSingleFile({ ...e, type: 'category' }))
              );
            }
            const body = {
              name: form.getFieldValue('categoryName'),
              avatar: avatar?.[0]?.url?.[0] || category?.avatar,
            };
            try {
              await dispatch(updateCategory({ id: category?.id, body }));
              Notification('success', 'Create category success!');
            } catch (error: any) {
              Notification('error', 'Create category fail!', error);
            } finally {
              resetState();
            }
          } else {
            const avatar = await handleUploadSingleImage(values.avatar, e =>
              dispatch(uploadSingleFile({ ...e, type: 'category' }))
            );
            console.log(avatar);
            const body = {
              name: form.getFieldValue('categoryName'),
              avatar: avatar[0].url[0],
            };
            try {
              await dispatch(createCategory(body));
              Notification('success', 'Create category success!');
            } catch (error: any) {
              Notification('error', 'Create category fail!', error);
            } finally {
              resetState();
            }
          }
        }
      } finally {
        setIsLoading(false);
      }
    });
  };
  return (
    <>
      <Modal
        title={
          isEdit ? (
            <TitleText>Chỉnh sửa danh mục</TitleText>
          ) : (
            <TitleText>Tạo mới danh mục</TitleText>
          )
        }
        bodyHeight="auto"
        centered
        visible={isVisible!}
        onCancel={onCancel}
        footer={[
          <CButton key="1" onClick={onCancel} type={ButtonType.Text}>
            Hủy bỏ
          </CButton>,
          <CButton
            loading={isLoading}
            key="2"
            onClick={() => handleSubmitBodyCreate()}
            type={ButtonType.Primary}
          >
            {isEdit ? 'Xác nhận' : 'Thêm mới'}
          </CButton>,
        ]}
      >
        <Text>Hình ảnh đại diện:</Text>
        <Form form={form} layout="vertical">
          <Form.Item name="upload">
            <WrapperUpload>
              <UploadSingle
                valuePropName="file"
                name="avatar"
                width="448px"
                height="212px"
                initUrl={form.getFieldValue('avatar') || category?.avatar}
                getValueFromEvent={handleChangeAvatar}
              />
            </WrapperUpload>
          </Form.Item>
          <Text> Tên danh mục: </Text>
          <Form.Item
            name="categoryName"
            rules={[
              { required: true, message: 'Vui lòng nhập tên danh mục.' },
              ({ getFieldValue }) => {
                return {
                  validator(_, value) {
                    if (value && value.length > 0 && checkOnlySpaces(value)) {
                      return Promise.reject(
                        'Tên danh mục không dùng chuỗi rỗng!'
                      );
                    }
                    if (
                      value &&
                      value.length > 0 &&
                      checkFirstCharIsNotSpace(value)
                    ) {
                      return Promise.reject(
                        'Tên danh mục không được bắt đầu là khoảng trắng!'
                      );
                    }

                    return Promise.resolve();
                  },
                };
              },
            ]}
          >
            <Input styles={{ marginTop: '12px' }} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

CategoryModal.defaultProps = {
  isVisible: false,
  isEdit: false,
};
export default CategoryModal;

const WrapperUpload = styled.div`
  margin: 12px auto;
  span {
    margin: 0;
    width: 100%;
  }

  .ant-upload {
    width: 100% !important;
  }
`;
